  .manageInfo {
    padding: 87px 0 80px 0;
    display: flex;
    justify-content: center;
    width: 1200px;
    margin: 0 auto;

    .img {
      width: 475px;
      height: 533px;
      margin: 0 52px 0 24px;
    }

    .manageContent {
      width: 625px;
      // height: 560px;
      // margin-bottom: 6px;

      .name {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        span {
          margin-right: 12px;
          font-family: FZLTT;
          font-weight: normal;
          font-size: 36px;
          color: #d2281c;
          line-height: 46px;
          letter-spacing: 3px;
          text-align: left;
          font-style: normal;
        }

        .nameline {
          width: 76px;
          height: 5px;
          background: linear-gradient(
            270deg,
            rgba(235, 81, 60, 0) 0%,
            #d2281c 100%
          );
        }
      }

      .desc {
        font-family: FZRUI;
        font-weight: normal;
        font-size: 20px;
        color: #000000;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        margin-bottom: 8px;
      }

      .line {
        width: 575px;
        height: 2px;
        background: #d8d8d8;
        margin-bottom: 16px;
      }

      .descContent {
        font-family: FZRUI;
        font-weight: normal;
        font-size: 16px;
        color: #666666;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        .itemDesc {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;

          .dot {
            width: 11px;
            height: 11px;
            background: #d2281c;
            border-radius: 50%;
            margin-right: 8px;
            margin-top: 2px;
          }
        }

        p {
          display: flex;
          align-items: center;

          border-bottom: 1px solid transparent;
        }

        p:hover {
          display: flex;
          align-items: center;
          li {
            width: 11px;
            height: 11px;
            background: #d2281c;
            border-radius: 50%;
          }
          color: #333333;
          border-bottom: 1px solid #333;
          display: inline-block;
          cursor: pointer;
        }

        // p:nth-child(2) {
        //   margin-bottom: 13px;
        //   color: #333333;
        //   border-bottom: 1px solid #333;
        //   display: inline-block;
        // }

        .itemDesc:last-child {
          // margin-bottom: 24px;
        }
      }

      .line {
        width: 575px;
        height: 2px;
        background: #d8d8d8;
        margin-bottom: 15px;
      }

      .thing {
        .itemThing {
          font-family: FZRUI;
          font-weight: normal;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          margin-bottom: 17px;
          display: flex;
          align-items: flex-start;

          .dot {
            width: 11px;
            height: 11px;
            background: #d2281c;
            border-radius: 50%;
            margin-right: 8px;
            margin-top: 2px;
          }

          .date {
            font-family: FZLT;
            font-weight: normal;
            font-size: 16px;
            color: #000000;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }

        .itemThing:nth-child(3) {
          display: flex;
          align-items: flex-start;

          .dot {
            margin-top: 6px;
          }
          // line-height: 24px;
          .word {
            line-height: 24px;
            width: 565px;
          }
        }
      }
    }
  }

@primary-color: #1DA57A;