.TitleCom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .TitleWrapper {
    display: flex;
    align-items: center;

    .chinaTit {
      font-family: FZLTT;
      font-weight: normal;
      font-size: 36px;
      color: #333333;
      line-height: 46px;
      letter-spacing: 3px;
      text-align: left;
      font-style: normal;
    }

    .leftLine {
      width: 230px;
      height: 4px;
      background: linear-gradient(90deg, rgba(239, 74, 52, 0) 0%, #EF4A34 100%);
      margin-right: 32px;
    }

    .rightLine {
      width: 230px;
      height: 4px;
      background: linear-gradient(90deg, #EF4A34 0%, rgba(239, 74, 52, 0) 100%);
      margin-left: 32px;
    }
  }

  .engTit {
    font-family: FZRUI;
    font-weight: normal;
    font-size: 12px;
    color: #333333;
    line-height: 13px;
    text-align: left;
    font-style: normal;
    text-transform: uppercase;
  }

}
@primary-color: #1DA57A;