.footer {
  width: 100%;
  height: 265px;
  background: #252525;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.6);
}

.footerTop {
  width: 1200px;
  flex: 1;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
}

.footerIem {
  height: 60px;
  display: flex;
  flex-direction: column;
  // gap: 8px;

  & > div {
    flex: 1;
  }
}

.footerBottom {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.2);
  color: #999;
  text-align: center;
  font-size: 14px;
}

@primary-color: #1DA57A;