.side {
  // width: 100%;
  // height: 100vh;

  .sideNavWrap {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    background-image: url("../../../assets/images//opacity-30.png");
    // transition: all 0.3s ease 0ms;
  }

  .sideNav {
    position: fixed;
    top: 0;
    // width: 600px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 5px 14px 3px #f2f2f2 inset;
    z-index: 100;
    transition: all 0.7s ease 0ms;
    padding: 113px 44px 0 48px;

    .sideTitle {
      font-family: FZLT;
      font-weight: normal;
      font-size: 20px;
      color: #333333;
      line-height: 24px;
      text-align: justify;
      font-style: normal;
      padding-bottom: 17px;
      border-bottom: 2px solid #d8d8d8;
      // width: 450px;
      margin-bottom: 67px;
    }

    .sideContent {
      display: flex;
      // width: 450px;
      padding: 0 25px;

      .sideList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 86px;
        // grid-row-gap: 10px;
        // justify-content: space-between;
        .itemSide {
          // width: 150px;
          // margin-right: 80px;
          margin-bottom: 20px;
          .itemTitle {
            display: flex;
            align-items: center;

            .dot {
              width: 8px;
              height: 8px;
              background: #d8d8d8;
              margin-right: 5px;
              border-radius: 50%;
            }
            font-family: FZRUI;
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            color: #333333;
            line-height: 18px;
            text-align: justify;
            font-style: normal;
            cursor: pointer;
            margin-bottom: 24px;
          }

          .itemName {
            display: flex;
            align-items: center;

            .dot {
              width: 8px;
              height: 8px;
              background: transparent;
              margin-right: 5px;
              border-radius: 50%;
            }
            font-family: FZRUI;
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 18px;
            text-align: justify;
            font-style: normal;
            cursor: pointer;
            margin-bottom: 24px;
          }

          .itemName:hover {
            // font-family: FZLT;
            // font-weight: normal;
            // font-size: 18px;
            // color: #333333;
            // line-height: 21px;
            // text-align: justify;
            // font-style: normal;
            // border-bottom: 1px solid #333;
          }
        }

        .itemSide:nth-child(2n) {
          margin-right: 100px;
        }
      }
    }
  }
}

@primary-color: #1DA57A;