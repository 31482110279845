.header {
  width: 100%;
  height: 83px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  
  .headerWrapper {
    // min-width: 1200px;
    width: 1200px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    .leftWrap {
      display: flex;
    }
    .logo {
      width: 134px;
      height: auto;
      margin-right: 158px;
      cursor: pointer;
    }

    .menuInfos {
      display: flex;
      align-items: center;
      .menusWrap {
        
        margin-right: 30px;
          :global {


        .ant-menu-item {
          // display: flex;
          // align-items: center;

          .ant-menu-title-content {
            // margin-inline-start: 5px;
            // margin-left: 5px;
          }
        }

        .ant-menu-horizontal {
          border-bottom: none;
        }

        .ant-menu-submenu-selected {
          a {
            color: #ff3b30;
          }
        }

        .ant-menu-submenu-title {
          color:inherit // a {
          //   color: #333;
          // }
        }
      }
      }


      }

            .fold {
        width: 37px;
        height: auto;
        cursor: pointer;
      }


    }
}

@primary-color: #1DA57A;