.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .banner {
    width: 100%;
    height: 1000px;
    background-image: url(../../assets/images/home/banner.png);
    background-size: cover;
    /* 背景图按比例缩放以全部显示 */
    background-repeat: no-repeat;
    /* 背景图不重复 */
    background-position: top left;

    /* 背景图定位到左上角 */
    .bannerWrapper {
      max-width: 1200px;
      margin: 0 auto;

      .descContent {
        display: flex;
        justify-content: space-between;

        .info {
          padding-top: 261px;

          .title {
            font-weight: normal;
            font-size: 48px;
            color: #ffffff;
            line-height: 61px;
            font-style: normal;
            margin-bottom: 26px;
            font-family: FZLTT;
          }

          ul {
            display: flex;
            margin-bottom: 37px;

            li {
              width: 13px;
              height: 13px;
              background: #fff;
              margin-right: 14px;
              border-radius: 50%;
            }
          }

          .desc {
            width: 625px;
            white-space: word-wrap;
            font-weight: normal;
            font-size: 16px;
            color: #ffffff;
            line-height: 36px;
            text-align: justify;
            font-style: normal;

            span {
              font-weight: bold;
              font-family: FZLT;
            }
          }
        }

        .swiperContent {
          display: flex;
          flex-direction: column;
          align-items: end;
          padding-top: 146px;

          .swiperBtnContent {
            width: 200px;
            height: 75px;
            margin-bottom: 23px;
            display: flex;
            justify-content: end;

            :global {

              .leftBtn,
              .rightBtn {
                width: 75px;
                height: 75px;
                cursor: pointer;
              }

              .leftBtn {
                margin-right: 17px;
              }
            }
          }

          .swiperImg {
            width: 392px;
            height: 502px;

            .item {
              // width: 100%;
              width: 392px;
              height: 502px;
              position: relative;
              display: block;
              background-size: cover;
              /* 背景图按比例缩放以全部显示 */
              background-repeat: no-repeat;
              /* 背景图不重复 */
              background-position: top left;
              /* 背景图定位到左上角 */

              .itemDesc {
                position: absolute;
                right: 24px;
                bottom: 26px;

                .num {
                  text-align: end;
                  font-family: FZLTT;
                  font-weight: normal;
                  font-size: 60px;
                  color: #ffffff;
                  line-height: 75px;
                  font-style: normal;
                }

                .title {
                  display: flex;
                  flex-direction: column;
                  align-items: end;
                  font-family: FZRUI;
                  font-weight: normal;
                  font-size: 24px;
                  color: #ffffff;
                  line-height: 28px;
                  text-align: justify;
                  font-style: normal;

                  .en {
                    font-weight: normal;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 13px;
                    text-align: justify;
                    font-style: normal;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }

    .operate {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 11px;

      .img {
        width: 288px;
        height: 64px;
        margin-bottom: 18px;
      }

      .operateBtn {
        display: flex;
        padding-top: 89px;
        justify-content: end;

        .itemBtn {
          width: 76px;
          height: 76px;
          border: 1px solid #ffffff;
          margin-right: 20px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: FZLT;
          font-weight: normal;
          font-size: 16px;
          color: #ffffff;
          line-height: 24px;
          letter-spacing: 1px;
          font-style: normal;
        }

        .itemBtnActive {
          width: 76px;
          height: 76px;
          background: #ffffff;
          border: 1px solid #fff;
          font-weight: normal;
          font-size: 16px;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          font-style: normal;
        }

        .itemBtn:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .enterpriseHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 87px 0 80px 0;
    background: #F7F9FA;

    .tab {
      display: inline-block;
      margin-bottom: 71px;

      :global {
        .tab-wrapper {
          width: auto;
          display: inline-block;
          margin: 0 auto;
          cursor: pointer;

          .swiper-pagination {
            position: relative;
            display: flex;
            padding: 0 18px;
            // border-bottom: 3px solid #ccc;
            width: auto;
            display: inline-block;
            padding-bottom: 4px;

            .tab-item {
              font-weight: normal;
              font-size: 16px;
              color: #333333;
              line-height: 19px;
              text-align: justify;
              font-style: normal;
              margin-right: 49px;
              cursor: pointer;
              padding-bottom: 19px;
              opacity: 0.6;
            }

            .tab-item:last-child {
              margin-right: 0;
            }

            .tab-item-active {
              border-bottom: 6px solid #ef4a34;
              opacity: 1;
              color: #333333;
              font-family: FZLT;
            }
          }
        }
      }
    }

    .content {
      // width: 1200px;
      width: 100%;

      .item {
        width: 100%;
        height: auto;
        // height: 497px;
      }
    }

    .btnWrapper {
      width: 1200px;
      display: flex;
      justify-content: end;

      :global {
        .enterProse-prev {
          width: 74px;
          height: auto;
          margin-right: 39px;
          cursor: pointer;
        }

        .enterProse-next {
          width: 74px;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }

  .managerWrapper {
    background: #fff;
  }

  .coreBusiness {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 87px 0 80px 0;
    background: #F7F9FA;

    .title {
      display: flex;

      .titleLeft {
        width: 213px;
        height: 85px;
        padding-left: 23px;
        margin-right: 355px;
      }

      :global {
        .tab {
          margin-bottom: 79px;
          padding-top: 27px;

          .tab-wrapper {
            width: auto;
            display: inline-block;
            margin: 0 auto;
            cursor: pointer;

            .swiper-pagination {
              position: relative;
              display: flex;
              padding: 0 18px;
              // border-bottom: 3px solid #ccc;
              width: auto;
              display: inline-block;
              padding-bottom: 4px;

              .tab-item {
                font-weight: normal;
                font-size: 24px;
                color: #333333;
                line-height: 45px;
                text-align: justify;
                font-style: normal;
                margin-right: 49px;
                cursor: pointer;
                padding-bottom: 19px;
                opacity: 0.6;
              }

              .tab-item:last-child {
                margin-right: 0;
              }

              .tab-item-active {
                border-bottom: 6px solid #ef4a34;
              }
            }
          }
        }
      }
    }

    .content {
      width: 100%;
      // max-width: 1200px;
      // margin-left: auto;
      // margin-right: auto;

      .wrapperContent {
        width: 100%;
        position: relative;
        // margin-bottom: 63px;
        // :global {
        //   .swiper {
        //     overflow: visible;
        //   }
        // }

        .tabWrapper {}

        .item {
          // width: 532px;
          // height: 446px;
          position: relative;
          cursor: pointer;
          /* 替换为你的图片路径 */
          // background-size: cover; /* 背景图按比例缩放以全部显示 */
          // background-repeat: no-repeat; /* 背景图不重复 */
          // background-position: top left; /* 背景图定位到左上角 */
          // color: #fff;



          img {
            width: 100%;
            height: 100%;
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 1.5%;
            background-color: rgba(0, 0, 0, 0.5);
            /* 半透明黑色遮罩 */
            // display: none; /* 默认不显示 */
            z-index: 0;
            /* 确保遮罩在内容之上 */
            border-radius: 13px;
            // transition: all 0.7s ease 0ms;
          }

          .contentWrapper {
            position: relative;
            /* 确保内容相对于其容器定位 */
            height: 100%;
            /* 填满容器高度 */
          }

          .content {
            position: absolute;
            bottom: 32px;
            left: 32px;
            z-index: 50px;
            width: 100%;
            transition: transform 0.3s ease-in-out;
            // transform: translate(50px, 50px);
            /* 向上移动整个内容区域，使其超出视口 */
          }

          .titleContent {
            .title {
              font-family: FZLTT;
              font-weight: normal;
              font-size: 27px;
              color: #ffffff;
              line-height: 35px;
              letter-spacing: 2px;
              text-align: left;
              font-style: normal;
            }

            .tit {
              font-weight: normal;
              font-size: 9px;
              color: #ffffff;
              line-height: 10px;
              letter-spacing: 3px;
              text-align: left;
              font-style: normal;
              text-transform: uppercase;
            }
          }

          .content p {
            margin: 0;
            // padding: 10px;
            box-sizing: border-box;
            position: relative;
            z-index: 1000;
          }

          .content .line1 {
            // padding-bottom: 30px;
          }

          .content .otherLine {
            display: none;
          }

          .otherLine {
            margin-top: 22px;

            .desc {
              font-weight: normal;
              font-size: 12px;
              color: #ffffff;
              line-height: 21px;
              letter-spacing: 1px;
              text-align: left;
              font-style: normal;
              width: 410px;
              word-break: break-all;
              margin-bottom: 10px;
            }

            .lists {
              display: flex;
              flex-wrap: wrap;

              .itemList {
                padding: 6px 8px;
                border-radius: 12px;
                border: 1px solid #ffffff;
                font-weight: normal;
                font-size: 11px;
                color: #ffffff;
                text-align: left;
                font-style: normal;
                margin-right: 6px;
              }
            }
          }

          .content .isShow {
            display: block;
            transition: all 0.7s ease 0ms;
          }

          .content .noShow {
            display: none;
            transition: all 0.7s ease 0ms;
          }

          /* 鼠标悬停状态：显示所有行 */
          .content.hovered {
            transition: all 0.7s ease 0ms;
            // transform: translateY(0);
          }
        }

        :global {
          .swiper-button-prev1 {
            width: 75px;
            height: 75px;
            cursor: pointer;
            left: 10%;
            top: 40%;
          }

          .swiper-button-next1 {
            width: 75px;
            height: 75px;
            cursor: pointer;
            right: 10%;
            position: absolute;
            top: 40%;
          }
        }
      }

      :global {
        .next {
          width: 75px;
          height: 75px;
          cursor: pointer;
        }

        .prev {
          width: 75px;
          height: 75px;
          cursor: pointer;
        }
      }
    }
  }

  .newsCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 87px 0 80px;
    width: 100%;
    background: #fff;

    .title {
      width: 213px;
      height: 84px;
      margin-bottom: 65px;
    }

    .tab {
      margin-bottom: 51px;

      :global {
        .tab-wrapper {
          width: auto;
          display: inline-block;
          margin: 0 auto;
          cursor: pointer;

          .swiper-pagination {
            position: relative;
            display: flex;
            padding: 0 18px;
            // border-bottom: 3.15px solid #ccc;
            width: auto;
            display: inline-block;
            padding-bottom: 4px;

            .tab-item {
              font-weight: normal;
              font-size: 16px;
              color: #333333;
              line-height: 19px;
              text-align: justify;
              font-style: normal;
              margin-right: 49px;
              cursor: pointer;
              padding-bottom: 20px;
              opacity: 0.6;
            }

            .tab-item:last-child {
              margin-right: 0;
            }

            .tab-item-active {
              border-bottom: 6px solid #ef4a34;
              opacity: 1;
              color: #333333;
              font-family: FZLT;
            }
          }
        }
      }
    }

    .newsContents {
      width: 100%;
      position: relative;

      :global {

        // .swiper {
        //   overflow: visible;
        // }
        .swiper-slide-prev {
          opacity: 0.2;
        }

        .swiper-slide-next {
          opacity: 0.2;
        }
      }

      .item {
        // width: 100%;
        // height: 497px;
        // height:5 252px;
        width: 100%;
        height: 240px;
        margin-bottom: 25px;
        object-fit: cover;
        border-radius: 20px;
      }

      .itemActive {
        background: #d8d8d8;
        box-shadow: 0px 0 7px 0px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        border: 0px solid #979797;
      }

      .contentDesc {
        text-align: center;
        // margin-bottom: 46px;

        .desc {
          font-weight: normal;
          font-size: 16px;
          color: #333333;
          line-height: 28px;
          letter-spacing: 1px;
          font-style: normal;
          margin-bottom: 13px;
        }

        .time {
          font-weight: normal;
          font-size: 16px;
          color: #333333;
          line-height: 28px;
          font-style: normal;
        }
      }

      :global {
        .swiper-button-left {
          width: 75px;
          height: 75px;
          cursor: pointer;
          left: 10%;
          top: 20%;
          position: absolute;
        }

        .swiper-button-right {
          width: 75px;
          height: 75px;
          cursor: pointer;
          right: 10%;
          position: absolute;
          top: 20%;
        }
      }
    }
  }

  .investLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 87px 0 80px;
    background: #F7F9FA;

    .title {
      width: 319px;
      height: 84px;
      margin-bottom: 65px;
    }

    .desc {
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      line-height: 36px;
      font-style: normal;
      margin-bottom: 19px;
    }

    .imgContent {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 92px;

      .img {
        width: 388px;
        height: auto;
      }
    }

    .customerLogos {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;

      .customerLogosLogos {
        display: flex;
        mask-image: linear-gradient(var(--mask-direction, to right),
            #0000,
            #000 10%,
            #000 90%,
            #0000);
        overflow: hidden;

        @keyframes marquee {
          0% {
            transform: translateX(0);
          }

          100% {
            transform: translateX(-100%);
          }
        }

        .logoAll {
          display: flex;
          animation: marquee 80s linear infinite;

          .logoItem {
            // width: 200px;
            // height: 30px;
            height: auto;
            margin-right: 65px;
          }
        }
      }
    }
  }

  .contactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 87px 0 80px;
    background: #fff;

    .title {
      width: 213px;
      height: 84px;
      margin-bottom: 61px;
    }

    .imgContent {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 46px;

      .img1 {
        width: 593px;
        height: auto;
        cursor: pointer;
      }

      .img2 {
        width: 287px;
        height: auto;
        cursor: pointer;
      }

      .img3 {
        width: 289px;
        height: auto;
        cursor: pointer;
      }
    }

    .contactWay {
      display: flex;
      justify-content: space-between;
      width: 1200px;

      .contactInfo {
        display: flex;

        .phone {
          width: 79px;
          height: 79px;
          margin-right: 26px;
        }

        .desc {
          padding-top: 4px;

          .p1 {
            font-weight: normal;
            font-family: FZLTT;
            font-size: 28px;
            color: #333333;
            line-height: 35px;
            letter-spacing: 2px;
            text-align: left;
            font-style: normal;
            margin-bottom: 8px;
          }

          .p2 {
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: left;
            font-style: normal;
            margin-bottom: 11px;
          }

          .p3 {
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: left;
            font-style: normal;
            margin-bottom: 1px;
          }

          .p4 {
            font-weight: normal;
            font-size: 16px;
            color: #333333;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: left;
            font-style: normal;
          }
        }
      }

      .internet {
        display: flex;

        .internetContent {
          margin-right: 30px;
          width: 210px;
          height: 49px;
          display: flex;

          li {
            width: 100%;
            display: flex;
            justify-content: space-between;

            li {
              width: 49px;
              height: 49px;
              border-radius: 50%;
              cursor: pointer;

              img {
                width: 49px;
                height: auto;
                display: block;
              }

              &:hover,
              &.active {
                box-shadow: 0px 12px 16px 0px #ccd8e6;
              }
            }
          }
        }

        .qrCode {
          width: 166px;
          height: 166px;
          border: 1px solid #979797;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}

:global {
  .tabWrapper {
    width: auto;
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;

    .tabContainer {
      position: relative;
      display: flex;
      padding: 0 18px;
      border-bottom: 3.15px solid #ccc;
      width: auto;
      display: inline-block;
      padding-bottom: 4px;

      .tabItem {
        font-weight: normal;
        font-size: 24px;
        color: #333333;
        line-height: 45px;
        text-align: justify;
        font-style: normal;
        margin-right: 49px;
        cursor: pointer;
        padding-bottom: 20px;
      }

      .tabItem:last-child {
        margin-right: 0;
      }

      .tabItemActive {
        border-bottom: 6px solid #ef4a34;
      }
    }
  }
}
@primary-color: #1DA57A;