@import "./reset";

@font-face {
  font-family: FZLTT;
  src: url("../assets/fonts/FZLTTHJW.TTF");
}

@font-face {
  font-family: FZLT;
  src: url("../assets/fonts/FZLTZHJW.TTF");
}

@font-face {
  font-family: FZRUI;
  src: url("../assets/fonts/FZRuiZHJW.TTF");
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #F7F9FA !important;
  box-sizing: border-box;
}

div,
p,
span {
  font-family: FZRUI;
}

/* 隐藏滚动条 */
// ::-webkit-scrollbar {
//   display: none;
//   width: 0;
//   height: 0;
//   color: transparent;
// }

@primary-color: #1DA57A;